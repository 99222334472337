import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from '@services/storage.service';

@Component({
	selector: 'ion-navigation-menu',
	templateUrl: './navigation-menu.component.html',
	styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit {
	data: string;
	home: any;
	contents: any;
	space: any;
	profile: any;

	constructor(
		private storageService: StorageService,
		private router: Router,
	) {}
	ngOnInit() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// Aquí puedes realizar las acciones que deseas cuando la URL cambia
				this.data = this.storageService.getMenuSelection();

				this.home = document.getElementById('Home');
				this.contents = document.getElementById('Contents');
				this.space = document.getElementById('Space');
				this.profile = document.getElementById('Profile');

				if (this.data == 'Home') {
					this.home.style.display = 'block';
					this.contents.style.display = 'none';
					this.space.style.display = 'none';
					this.profile.style.display = 'none';
				}else if (this.data == 'Contents') {
					this.home.style.display = 'none';
					this.contents.style.display = 'block';
					this.space.style.display = 'none';
					this.profile.style.display = 'none';
				} else if (this.data == 'Space') {
					this.home.style.display = 'none';
					this.contents.style.display = 'none';
					this.space.style.display = 'block';
					this.profile.style.display = 'none';
				} else if (this.data == 'Profile') {
					this.home.style.display = 'none';
					this.contents.style.display = 'none';
					this.space.style.display = 'none';
					this.profile.style.display = 'block';
				}
			}
		});
	}

	cambiarDisplay1() {
		this.storageService.saveMenuSelection('Home');
		this.data = this.storageService.getMenuSelection();

		if (this.data == 'Home') {
			this.home.style.display = 'block';
			this.contents.style.display = 'none';
			this.space.style.display = 'none';
			this.profile.style.display = 'none';
		}
	}

	cambiarDisplay2() {
		this.storageService.saveMenuSelection('Contents');
		this.data = this.storageService.getMenuSelection();

		if (this.data == 'Contents') {
			this.home.style.display = 'none';
			this.contents.style.display = 'block';
			this.space.style.display = 'none';
			this.profile.style.display = 'none';
		}
	}

	cambiarDisplay3() {
		this.storageService.saveMenuSelection('Space');
		this.data = this.storageService.getMenuSelection();

		if (this.data == 'Space') {
			this.home.style.display = 'none';
			this.contents.style.display = 'none';
			this.space.style.display = 'block';
			this.profile.style.display = 'none';
		}
	}

	cambiarDisplay4() {
		this.storageService.saveMenuSelection('Profile');
		this.data = this.storageService.getMenuSelection();

		if (this.data == 'Profile') {
			this.home.style.display = 'none';
			this.contents.style.display = 'none';
			this.space.style.display = 'none';
			this.profile.style.display = 'block';
		}
	}
}
